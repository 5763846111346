import React from 'react'

import '../../../../styles/showroomOptions.scss';

import SwitchField from '../../../Partials/form_fields/SwitchField';
import Input from '../../../Partials/form_fields/Input';
import { useGlobalContext } from '../../../context/GlobalContext';
import { dimensionsOptions } from '../../../../constants/constants';


export default function ShowroomOptions({
  showroom,
  handleChange,
  errors,
}){

  const option = showroom?.option;
  const [context] = useGlobalContext();
  const { exhibitionsList, activeExhibition } = context?.exhibitions;
  const exhibition = exhibitionsList?.find((ex) => ex._id === activeExhibition); 
  const exhibitionUnit = exhibition?.showroomType;
  const unit = dimensionsOptions?.find((d) => d.value === exhibitionUnit);

  function handleChangeOption(obj) {
    handleChange({ option: {...option, ...obj }});
  }
  console.log(errors)
  return(
    <>
      {showroom?.option &&     
        <div className="list-options card">
          <section>
            <div className="container-inputs row">
              <div className="col-3">
                <Input
                  className={"input"}
                  value={showroom.negotiatedPrice || 0}
                  label={`Montant négocié`}
                  placeholder={""}
                  type={"number"}
                  onChange={(val) => handleChange({
                    negotiatedPrice: val,
                  })}
                />
              </div>
            </div>
            {exhibition?.showroomOption?.displayShowroomSize &&
              <div className="container-inputs row">
                <div className="col-2">
                  <Input
                    className={"input"}
                    value={showroom.dimension || 0}
                    style={{ maxWidth: 50 }}
                    label={`Dimension (${unit?.label})`}
                    placeholder={""}
                    type={"number"}
                    onChange={(val) => handleChange({
                      dimension: val,
                    })}
                    error={errors?.dimension}
                  />
                </div>
              </div>
            }
            <div className="container-inputs row">
              <div className="col-2">
                <Input
                  className={"input"}
                  style={{ maxWidth: 50 }}
                  value={option?.table || 0}
                  label={`Nombre de tables souhaitées (1,5m par 0,75m)`}
                  placeholder={""}
                  type={"number"}
                  error={errors?.table}
                  onChange={(val) => handleChangeOption({
                    table: val,
                  })}
                />
              </div>
            </div>
            {exhibition?.showroomOption?.chairs?.isAvailable &&
              <div className="container-inputs row">
                <div className="col-2">
                  <Input
                    className={"input"}
                    value={option.chair || 0}
                    style={{ maxWidth: 50 }}
                    label={`Nombre de chaises`}
                    placeholder={""}
                    type={"number"}
                    error={errors?.chair}
                    onChange={(val) => handleChangeOption({
                      chair: parseInt(val),
                    })}
                  />
                </div>
              </div>
            }
            {exhibition?.showroomOption?.fridge?.isAvailable  &&
              <>
                {!exhibition?.showroomOption?.fridge?.isProvided ?
                    <div className="container-inputs row">
                      <div className="col-2">
                        <Input
                          className={"input"}
                          style={{ maxWidth: 50 }}
                          value={option?.fridgeLength || 0}
                          label={`Longueur de vitrine (m)`}
                          placeholder={""}
                          type={"number"}
                          onChange={(val) => handleChangeOption({
                            fridgeLength: val,
                          })}
                          error={errors?.fridgeLength}
                        />
                      </div>
                    </div>
                  :
                  <div className="container-inputs row">
                    <div className="col-2">
                      <Input
                        className={"input"}
                        style={{ maxWidth: 50 }}
                        value={option?.fridge || 0}
                        label={`Nombre de vitrines réfrigérées souhaitées (0,6m)`}
                        placeholder={""}
                        type={"number"}
                        onChange={(val) => handleChangeOption({
                          fridge: val,
                        })}
                        error={errors?.fridgeLength}
                      />
                    </div>
                  </div>
                }
              </>
            }
            {exhibition?.showroomOption?.power?.isAvailable  &&
              <div className="container-switch">
                <SwitchField
                  checked={option.power}
                  handleChange={(val) => handleChangeOption({ power: val})}
                  conditional={option.power ?
                    <div style={{ display: 'flex' }}>
                      <Input
                        className={"input"}
                        style={{ maxWidth: 50 }}
                        value={option.powerQuantity ? option.powerQuantity: 0 }
                        placeholder={""}
                        type={"number"}
                        onChange={(val) => handleChangeOption({ powerQuantity: val})}
                        error={errors?.powerQuantity}
                      />
                      <p style={{ marginLeft: 10, marginTop: 15 }}>Puissance électrique (en KW)</p>
                    </div>
                    : null
                  }
                >
                  <p>
                    Branchement électrique
                  </p>
                </SwitchField>
              </div>
            }
            <div className="standiste">
              <p className="section-title bold">Information sur le standiste</p>
              <div className="container-inputs row">
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option?.standiste?.name || ""}
                    label={"Nom"}
                    placeholder={""}
                    type={"text"}
                    onChange={(val) => handleChangeOption({
                      standiste: {
                        ...option.standiste,
                        name: val,
                      }
                    })}
                  />
                </div>
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option?.standiste?.email || ""}
                    label={"Adresse mail"}
                    placeholder={""}
                    format={"email"}
                    type={"string"}
                    onChange={(val) => handleChangeOption({
                      standiste: {
                        ...option.standiste,
                        email: val,
                      }
                    })}
                  />
                </div>
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option?.standiste?.phone || ""}
                    label={"Téléphone"}
                    placeholder={""}
                    type={"text"}
                    maskNumber={"## ## ## ## ##"}
                    format={"phone"}
                    onChange={(val) => handleChangeOption({
                      standiste: {
                        ...option.standiste,
                        phone: val,
                      }
                    })}
                  />
                </div>
              </div>
            </div> 
          </section>
        </div>
      }
    </>
  )

}
