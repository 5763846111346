import {
  GET_USER_LIST,
  GET_USER,
  ERROR_USERS,
  MESSAGE_USERS,
  EXPORT_PRESENCES,
  SET_USER_FILTERS
} from "../actions/types"

const initialState = {
  usersList: null,
  user: {},
  error: {},
  message: "",
  exportPresencesList: null,
  filters: {
    search: '',
  }
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_PRESENCES:
      return {...state, exportPresencesList : action.payload}
    case SET_USER_FILTERS:
      return {...state, filters: action.payload }
    case GET_USER_LIST:
      return {...state, usersList : action.payload}
    case MESSAGE_USERS:
      return {...state, message : action.payload}
    case ERROR_USERS:
      return {...state, error : action.payload}
    case GET_USER:
      return {...state, user : action.payload}
    default:
      return {...state};
  }
}
