const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius : "12px",
    border : "solid 1px #B2B2B2",
    outline : 'none',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
       border: state.isFocused ? "solid 1px #B2B2B2" : "solid 1px #B2B2B2"
    }
  }),
  valueContainer: (provided, state ) => {
    const value = {
      ...provided,
      minHeight: "38px",
    }
    return value;
  },
  menuList: (provided, state ) =>{
    return {
      ...provided,
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },
  menu: (provided, state) => ({
    ...provided,
    borderRadius : "12px",
    border : "solid 1px #B2B2B2",
    overflow : "hidden",
    marginTop: "3px",
    boxShadow: state.isFocused ? 0 : 0,
  }),
  option: (provided, state) => {
    const option = {
      ...provided,
      fontFamily : "Montserrat-Regular",
      padding : "0px 12px",
      fontSize : "14px",
      lineHeight: "38px"
    }
    if (state.data?.color) {
      option.color = state.data?.color;
      option.backgroundColor = 'transparent'
    }
    if (state.data?.color && state?.isSelected) {
      option.color = state.data?.color;
      option.backgroundColor = 'transparent'
    }
    return option;
  },
  noOptionsMessage : (provided, state) => ({
    ...provided,
    fontFamily : "Montserrat-Regular",
    padding : "5px 12px",
    fontSize : "14px",
    height: "38px",
    lineHeight: "28px"
  }),
  singleValue: (provided, state ) => {
    const value = {
      ...provided,
      fontSize : "14px",
      fontFamily : "Montserrat-Regular"
    }
    if (state.data?.color) {
      value.color = state.data?.color;
      value.backgroundColor = 'transparent'
    }
    return value;
  },
  multiValue: (provided, state) => ({
    ...provided,
    fontSize : "14px",
    backgroundColor: '#005FBF',
    borderRadius : "12px",
    padding: "2px",
    fontFamily : "Montserrat-Regular",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    margin: 3,
    color: '#FFFFFF'
  }),
  multiValueRemove: (provided, state) =>({
  ...provided,
  padding: 2,
  marginRight: 3,
  borderRadius: '15px',
  color : '#FFFFFF',
  cursor:'pointer',
    '&:hover': {
      color : "#FF8900",
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : "14px",
    fontFamily : "Montserrat-Regular"
  })
}
export default selectStyle;