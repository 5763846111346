import React , { useEffect , useCallback  , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , updateProviderAction , getEditListsAction, getProviderVisitsAction } from '../../../actions/providersActions';

import Company from '../../Partials/Company';
import Contacts from '../../Partials/Contacts';
import Brands from '../../Partials/Brands';
import Showroom from './Providers_Partials/Showroom';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import Visits from './Providers_Partials/Visits/Visits';
import IconBtn from '../../Partials/IconBtn/IconBtn';
import { exportProviderOrdersById } from '../../../actions/ordersActions';
import { exportProviderOffersById } from '../../../actions/offersActions';

export default function ProviderView({ setActivePage , match}) {
  const [ context , dispatch ] = useGlobalContext();
  const [ editNote , setEditNote ] = useState(false);
  const [ note , setNote ] = useState('');
  const [ visits, setVisits] = useState([]);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportOffersIsLoading, setExportOffersIsLoading] = useState(false);

  const provider = context?.providers?.provider;
  const daysOptions = context.providers?.lists?.days;

  const getProvider = useCallback(async() => {
    getProviderAction( dispatch, match.params.id);
    const res = await getProviderVisitsAction(dispatch, match.params.id);
    if (res) {
      setVisits(res);
    }
  }, [dispatch, match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getProvider();
    setActivePage("providers")
    getEditLists();
  }, [getProvider, getEditLists, setActivePage]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function cancelNote() {
    setEditNote(!editNote);
    setNote('');
  }

  function updateNote() {
    const providerUpdated = {
      id: provider.company._id,
      note: note,
    }
    setEditNote(!editNote);
    updateProviderAction(dispatch, providerUpdated);
  }

  useEffect(() => {
    if (provider?.company?.note) {
      setNote(provider.company.note)
    }
  }, [provider]);

  useEffect(() => {
    if (context.providers.message === "Company successfully updated!") {
      successUpdate();    }

    if (context.providers.message === "company reset ok") {
      successReset();
    }

  });

  function successUpdate() {
    getProviderAction(dispatch , match.params.id);
    store.addNotification({
      message: "Entreprise mise à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : null
    });
    // getProvider();
  }

  function successReset() {
    store.addNotification({
      message: "Entreprise réinitialisée avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : null
    });
    getProvider();
  }

  function renderNote() {
    return (
      <div className="col-1">
        <div className="title">
          <h2><Translate id="company.notes"/></h2>
          {!editNote ? 
            <button className="btn-edit" style={{ width : 100}}
              onClick={()=> setEditNote(!editNote)}
            >
              <Translate id="btn.edit"/>
            </button>
            :
            null
          }
        </div>
        {editNote ? 
          <div className="card notes">
            <textarea autoFocus value={note} onChange={(e) => setNote(e.target.value)}/>
            <button className="btn-edit" style={{ margin: 5, width : 100}}
              onClick={()=> cancelNote()}
              >
              <Translate id="btn.cancel"/>
            </button>
            <button className="btn-edit success" style={{ margin: 5,  width : 100}}
              onClick={()=> updateNote()}
              >
              <Translate id="btn.edit"/>
            </button>
          </div>
          :
          <>
          {note ? 
            <div className="card notes">
              <p>{note}</p>
            </div>
            :
            null
          }
          </>
        }
      </div>
    )
  }


  return (
    <div className="page-container">
      {provider?.company &&
        <div>
          <header className="page-header">
            <div className="icon">
              <img src={providerIcon} alt="icon-provider"/>
            </div>
            <h1>{provider.company.name}</h1>
            <div className="export">
              <div className="btn">
                <IconBtn
                  label={"Exporter les offres"}
                  icon="export"
                  isLoading={exportOffersIsLoading}
                  handleClick={async() => {
                    setExportOffersIsLoading(true);
                    await exportProviderOffersById(dispatch , provider.company._id, provider.company.name);
                    setExportOffersIsLoading(false);
                  }} 
                />
              </div>
              <div className="btn">
                <IconBtn
                  label={"Exporter les commandes"}
                  icon="export"
                  isLoading={exportIsLoading}
                  handleClick={async() => {
                    setExportIsLoading(true);
                    await exportProviderOrdersById(dispatch , provider.company._id, provider.company.name);
                    setExportIsLoading(false);
                  }} 
                />
                </div>
            </div>
          </header>
          <main className="page-content">
            <div className="row space-between">
              <div className="col-2">
                <Link to={"/providers"} className="btn-edit mb-10">
                  <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
                </Link>
              </div>
            </div>

            <div className="row col-2">
              <div className="col-2">
                <Company
                  company={provider.company}
                  dispatch={dispatch}
                  contacts={provider.users}
                  root="provider" />
              </div>

              <div className="col-2">
                <Showroom showroom={provider?.showrooms[0]} activePage={"providers"} companyId={provider?.company?._id}/>
              </div>

            </div>

            <div className="row col-2">
              <div className="col-2">
                {renderNote()}
                <div style={{ marginTop: 10 }}>
                  <Contacts
                    contacts={provider.users}
                    daysOptions={daysOptions}
                    companyId={provider.company._id}
                    type="fournisseur"
                    root="provider" />
                </div>
                <div style={{ marginTop: 10 }}>
                <Brands
                  brands={provider.brands}
                  companyId={provider.company._id} 
                  root="provider" />
                </div>
              </div>
              <div className="col-2">
                <Visits visits={visits} />
              </div>
            </div>
          </main>
        </div>
      }
    </div>
  )
}