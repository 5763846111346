import React , { useEffect , useState , useCallback } from 'react';
// import { Translate } from "react-localize-redux";

import UsersRow from './UserRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import { useGlobalContext } from '../../context/GlobalContext';
import { getUsersListAction , exportPresencesAction } from '../../../actions/usersActions';

import searchIcon from "../../../assets/images/icons/search.svg"
import { EXPORT_PRESENCES, GET_USER, MESSAGE_USERS, SET_USER_FILTERS } from '../../../actions/types';
import { getExhibitionsListAction } from '../../../actions/exhibitionsActions';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function UsersList({ setActivePage }) {
  const [ context, dispatch ] = useGlobalContext();
  const { activeExhibition  , exhibitionsList } = context.exhibitions; 
  const { usersList  , filters, exportPresencesList } = context.users;
  const { search } = filters;
  const [ filtredList , setFiltredList ] = useState([]);
  const [ exportIsLoading , setExportIsLoading] = useState(false);

  const exhibitionName = exhibitionsList?.find((e) => e._id === activeExhibition)?.name;

  const getUserList = useCallback(() => {
    getUsersListAction(dispatch);
    getExhibitionsListAction(dispatch);
    dispatch({
      type : MESSAGE_USERS,
      payload : ""
    });

    dispatch({
      type : GET_USER,
      payload : {}
    });

    dispatch({
      type : EXPORT_PRESENCES,
      payload : null
    });

  }, [dispatch]);

  useEffect(() => {
    if (activeExhibition) {
      setActivePage("users");
      getUserList();
      window.scrollTo(0, 0);
    }
  }, [activeExhibition, getUserList, setActivePage]);

  useEffect(() => {
    if (usersList) {
      setFiltredList(usersList.filter((user) => {
        if (user.profile && user.profile.lastName && user.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.profile && user.profile.firstName && user.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.email && user.email.toLowerCase().includes(search.toLowerCase())) {
          return user;
        }
        else if (user.company && user.company.map((c) => c.name).join(',').toLowerCase().includes(search.toLowerCase())) {
          return user;
        }
        return null;
      }));
    }
  }, [search , usersList])

  function renderUserList() {
    if (!usersList) {
      return (
        <div style={{ height: '70vh'}}>
          <Loader />
        </div>
      )
    }
    if (filtredList && filtredList.length > 0) {
      return filtredList.map((user, i) => <UsersRow
        key={user._id}
        user={user}
        exhibitionName={exhibitionName}
      />);
    }
    if (search && filtredList.length === 0) {
      return <span className="text-error">Aucun résultat ne correspond à votre recherche</span>;
    }
  }

  function renderExportBtn() {
    if (exportPresencesList?.fileName) {
      return <ExportExcel
        callBack={() => {
          dispatch({
            type : EXPORT_PRESENCES,
            payload : null
          });
          setExportIsLoading(false);
        }}
        fileName={exportPresencesList.fileName}
        dataToExport={exportPresencesList.dataToExport}/>
    } else {
      return <IconBtn
        label={"Exporter les présences"}
        icon="export"
        isLoading={exportIsLoading}
        handleClick={() => {
          exportPresencesAction(dispatch);
          setExportIsLoading(true);
        }} 
      />
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des utilisateurs</h1>
      </header>
      <main className="page-content">
        <div className="row space-between">
            <div className="col-3 input">
              <input placeholder="Recherche"
                value={search}
                onChange={(e) => dispatch({
                  type: SET_USER_FILTERS,
                  payload: {
                    ...context.users.filters,
                    search: e.target.value,
                  }
                })}
              />
              <img className={'search-icon'} src={searchIcon} alt="search"/>
            </div>
            {renderExportBtn()}
        </div>
        {renderUserList()}
      </main>
    </div>
  )
}