import { getData , putData , postData } from './index';

import {
  GET_SHOPS_LIST,
  EXPORT_SHOPS_LIST,
  GET_SHOP,
  ERROR_SHOPS,
  MESSAGE_SHOPS
} from "./types"

export async function getShopsListAction(dispatch){
  let url = "/company/magasin"
  let shopsList;
  await getData( ERROR_SHOPS, url, dispatch , true , true).then((response)=>{
    shopsList = response.companies.map((c) => {
      const company = {...c };
      let  date = new Date(c.updatedAt);
      if (company.users.length > 0) {
        for (const u of company.users) {
          const userDate = new Date(u.updatedAt);
          if (userDate.getTime() > date.getTime()) {
            date =  userDate;
          }
        }
      }
      company.updatedAt = date;
      return company;
    });
  });
  dispatch({
    type : GET_SHOPS_LIST,
    payload : shopsList
  });
};

export async function getShopAction(dispatch , id){
  let url = "/company/" + id 
  let shop;
  await getData( ERROR_SHOPS, url, dispatch , true).then((response)=>{
    shop = response;
  });
  dispatch({
    type : GET_SHOP,
    payload : shop
  });
};

export async function updateShopAction(dispatch , data ){
  let url = "/company/" + data.id 
  let shopUpdated;
  await putData( GET_SHOP , ERROR_SHOPS, url, dispatch, data , true).then((response)=>{
    shopUpdated = response;
  });
  dispatch({
    type : MESSAGE_SHOPS,
    payload : shopUpdated.message
  });
};

export async function exportShopsListAction(dispatch , obj ){
  let url = "/export/magasin"
  let exportList;
  await postData(GET_SHOP , ERROR_SHOPS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_SHOPS_LIST,
    payload : exportList
  })
}

export async function invitShopAction(dispatch , id){
  let url = "/user/invite/magasin/" + id;
  var invit;

  await getData( ERROR_SHOPS, url, dispatch , true).then((response)=>{
    invit = response
  });
  dispatch({
    type : MESSAGE_SHOPS,
    payload : invit.message
  });
  return invit;
};

export async function invitAllShopsAction(dispatch){
  let url = "/user/invite/magasins";
  var invit;

  await getData( ERROR_SHOPS, url, dispatch , true).then((response)=>{
    invit = response
  });
  dispatch({
    type : MESSAGE_SHOPS,
    payload : "Les magasins ont été invités"
  });
  return invit;
};