import React from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import pending  from "../../../../assets/images/icons/status/pending.svg";
import confirmed from "../../../../assets/images/icons/status/valid.svg";
import refused  from "../../../../assets/images/icons/status/refused.svg";

const icons = {
  pending,
  confirmed,
  refused,
}


export default function OrderRow(props) {
  const { order } = props;
 
  function renderUpdatedLabel() {
    var updatedDate = new Date(order.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  function renderStatus(status) {
    return (<div className="company-status">
        <div className={`icon ${status}`}>
          {icons[status] && <img src={icons[status]} alt='status'/>}
        </div>
      </div>)
  };
  

  return (
      <Link to={`/order-view/${order._id}`} className="list-row">

        {renderUpdatedLabel()}

        <div className="info order">
          <p className="name">
            {order.company.name}
          </p>

          <p className="name">
            {order.products.length && order.products[0] && order.products[0].company && order.products[0].company.name}
          </p>

          <p>
            {order.products.length}
          </p>

          <p className="products">
            {order.products.length && _.uniqBy(order.products, "product").map((product, i ) => (<span key={i}>{product.product} </span>))}
          </p>

          <p className="total bold text-primary">
            {order.total.toFixed(2)} €
          </p>

          <div className="picto">
            {renderStatus(order.status)}
          </div>

        </div>

      </Link>

    // </Link>
  );
};
