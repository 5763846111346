import React , { useEffect , useCallback , useState } from 'react';
import { store } from 'react-notifications-component';

// import { Translate } from "react-localize-redux";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { triAlpha , triCentrale } from '../../../utils/utils';


import { useGlobalContext } from '../../context/GlobalContext';
import { getShopsListAction , exportShopsListAction, invitAllShopsAction } from '../../../actions/shopsActions';

import ShopRow from './Shops_Partials/ShopsRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import filterIcon from "../../../assets/images/icons/filters.svg";
import searchIcon from "../../../assets/images/icons/search.svg";

import '../../../styles/List.scss';
import { EXPORT_SHOPS_LIST, MESSAGE_SHOPS, SET_SHOP_FILTERS } from '../../../actions/types';
import UploadCompaniesModal from '../../Partials/UploadCompaniesModal/UploadCompaniesModal';
import { uploadCompaniesAction } from '../../../actions/providersActions';
import Modale from '../../Partials/Modale/Modale';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function ShopsList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const {
    scrollPos,
    shopsList,
    message,
    filters 
  } = context?.shops;

  const { search } = filters;
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByCentrale , setSortByCentrale ] = useState(false);
  const [ sortByCity , setSortByCity ] = useState(false);
  const [ sortByContacts , setSortByContacts] = useState(false);
  const [ sortByPresence , setSortByPresence ]= useState(false);
  const [ filtredList , setFiltredList ] = useState();
  const [ updateShops, setUpdateShops] = useState(false);
  const [ exportIsLoading , setExportIsLoading] = useState(false);


  const getshopsList = useCallback(() => {
    getShopsListAction(dispatch);
    setActivePage("shops");
    dispatch({
      type : 'SCROLL_ON_STANDS',
      payload : 0
    });
    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });
  }, [dispatch, setActivePage]);

  useEffect(() => {
      getshopsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, scrollPos)
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (shopsList?.length >= 0) {
      let shops = shopsList || [];
      shops.sort(function(a, b) {
        var dateA = new Date(a.updatedAt)
        var dateB = new Date(b.updatedAt)
        return dateA.getTime() - dateB.getTime()
      }).reverse();

      setFiltredList(shops);
    }

  }, [shopsList]);

  useEffect(() => {
    if (shopsList) {
      setFiltredList(shopsList.filter(shop => shop.name.toLowerCase().includes(search.toLowerCase()) ))
    }
    // eslint-disable-next-line
  }, [search, shopsList])

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByCity) {
        setFiltredList(newList.sort(function(a, b){
          var aCity = a.location.city.split(' ').join('').toLowerCase();
          var bCity = b.location.city.split(' ').join('').toLowerCase();
          if(aCity < bCity) { return -1; }
          if(aCity > bCity) { return 1; }
          return 0;
        }));
      } else {
        setFiltredList(newList.sort(function(a, b) {
          var aCity = a.location.city.split(' ').join('').toLowerCase();
          var bCity = b.location.city.split(' ').join('').toLowerCase();
          if(aCity < bCity) { return -1; }
          if(aCity > bCity) { return 1; }
          return 0;
        }).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCity]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByAlpha) {
        setFiltredList(newList.sort(triAlpha));
      } else {
        setFiltredList(newList.sort(triAlpha).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByCentrale) {
        setFiltredList(newList.sort(triCentrale));
      } else {
        setFiltredList(newList.sort(triCentrale).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCentrale]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByContacts) {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }));
      } 
    }
    // eslint-disable-next-line
  }, [sortByContacts]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByPresence) {
        setFiltredList(sortArrayByBooleen(newList , "presence"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "presence").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByPresence]);

  useEffect(() => {
    if (message === "Company successfully updated!") {
      successNotif("Magasin mis à jour avec succès !");
      getShopsListAction(dispatch);
    }
    // eslint-disable-next-line
  },[message]);

  function successNotif(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : MESSAGE_SHOPS,
      payload : null,
    });
  }


  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item && item[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };


  function renderExportBtn() {
    if (context.shops.exportShopsList && context.shops.exportShopsList.fileName) {
      return <ExportExcel
        callBack={() => {
          dispatch({
            type : EXPORT_SHOPS_LIST,
            payload : null
          });
          setExportIsLoading(false);
        }}
        fileName={context.shops.exportShopsList.fileName}
        dataToExport={context.shops.exportShopsList.dataToExport}/>
    } else {
      return <IconBtn
        label={"Exporter les magasins"}
        icon="export"
        isLoading={exportIsLoading}
        handleClick={() => {
          exportShopsListAction(dispatch , {});
          setExportIsLoading(true);
        }} 
      />
    }
  }

  function invitAllShops() {
    if (window.confirm("Souhaitez-vous inviter tous les magasins")) {
      invitAllShopsAction(dispatch);
    }
  }


  function renderShopsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList?.map((shop, i) => <ShopRow key={i} shop={shop}/>);
      } else if (search && filtredList?.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      }
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des magasins</h1>
      </header>
      <main className="page-content">
        <div className="row export">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search}
              onChange={(e) => dispatch({
                type: SET_SHOP_FILTERS,
                payload: {
                  ...filters,
                  search: e.target.value,
                }
              })}
            />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconBtn
              label={"Importer des magasins"}
              icon="import"
              handleClick={() => setUpdateShops(true)} 
            />
            <IconBtn
              label={"Inviter les magasins"}
              icon="invit"
              handleClick={() => invitAllShops()} 
            />
 
          {renderExportBtn()}
          </div>
        </div>
        <div className="filters">

          <div
            className="filter"
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du magasin</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(25%)'}}
            onClick={() => setSortByContacts(!sortByContacts)}>
            <span>Nombre d’invités</span>
            <img src={filterIcon} className={sortByContacts? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(40%)'}}
            onClick={() => setSortByCentrale(!sortByCentrale)}>
            <span>Centrale</span>
            <img src={filterIcon} className={sortByCentrale? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(120%)'}}
            onClick={() => setSortByCity(!sortByCity)}>
            <span>Ville</span>
            <img src={filterIcon} className={sortByCity? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(50%)'}}
            >
            <span>Utilisateur présent</span>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(20%)'}}
            onClick={() => setSortByPresence(!sortByPresence)}>
            <span>Magasin présent</span>
            <img src={filterIcon} className={sortByPresence? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-25%)'}}
            onClick={() => setSortByPresence(!sortByPresence)}>
            <span>Status</span>
          </div>

        </div>
        {shopsList ?
          renderShopsList()
          :
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        }
      </main>
      <Modale isOpen={updateShops}>
        {updateShops && <UploadCompaniesModal
          type="magasin"
          label="Mettre à jour les magasins"
          onSubmit={(data) => uploadCompaniesAction(dispatch, data)}
          close={() => setUpdateShops(false)}
        />}
      </Modale>
    </div>
  )
}