import React from 'react';
import HtmlEditor from '../../../../Partials/form_fields/HtmlEditor/HtmlEditor';
import { useGlobalContext } from '../../../../context/GlobalContext';

import styles from './Form.module.scss';

export default function BadgesForm({ handleChangeForm , width }) {
  const [ context ] = useGlobalContext();
  const { exhibition }  = context.exhibitions;
  return (
    <div className={`${styles.container} ${styles.badges}`} style={{ width }}>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <h2>Badge gauche</h2>
          <div className={styles.badge}>
            <HtmlEditor
              defaultValue={exhibition?.badgeLeft}
              onChange={(val) => handleChangeForm({ badgeLeft : val})}
            />
           </div>
        </div>
      </div>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <h2>Badge droit</h2>
          <div className={styles.badge}>
            <HtmlEditor
              defaultValue={exhibition?.badgeRight}
              onChange={(val) => handleChangeForm({ badgeRight : val})}
            />
           </div>
        </div>
      </div>
    </div>
  )
}
