export const requiredField = "Ce champs est requis";

export const dimensionsOptions = [
  { unit: "m2", value: 'square', label: 'Métre carré' },
  { unit: "m", value: 'linear', label: 'Métre linéaire' }
];

export const deliveryOptions = [
  { label: "Centrale", value: "Centrale" },
  { label: "Direct", value: "Direct" }
];

export const offersStatusOptions = [
  { label: "En attente", value: "pending" },
  { label: "Validée", value: "valid" },
  { label: "Refusée", value: "refused" },
  { label: "À corriger", value: "correction" },
];

export const companyStatusOptions = [
  { color: "#E17900", label: "En attente de validation", value: "pending" },
  { color: "#008800", label: "Validée", value: "valid" },
  // { color: "#E32A2A", label: "Refusée", value: "refused" },
];