import React, { useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { getExhibitionsListAction } from '../../../../actions/exhibitionsActions';
import { GET_EXHIBITION } from '../../../../actions/types';
import { useGlobalContext } from '../../../context/GlobalContext';
import InputSearch from '../../../Partials/InputSearch/InputSearch';
import Loader from '../../../Partials/Loader';
import SortItem from '../../../Partials/SortItem/SortItem';
import ExhibitionsRow from '../Exhibitions_Partials/ExhibitionsRow';
import styles from './ExhibitionsList.module.scss';

export default function ExhibitionsList({ setActivePage }) {
  const sortItems = [
    { value: 'name' , label: 'Nom du salon' , className: 'name' },
    { value: 'startDate' , label: 'Date de début'},
    { value: 'endDate' , label: 'Date de fin'},
    { value: 'satuts' , label: 'Statut'},
  ]

  const [ context , dispatch ] = useGlobalContext();
  const { exhibitionsList }  = context.exhibitions;

  const [ filteredList , setFilteredList]  = useState();
  const [ activeSort, setActiveSort ] = useState({ ...sortItems[1], isAsc: true });
  const [ search , setSearch ] = useState("");

  const getData = useCallback(() => {
    setActivePage("exhibition");
    getExhibitionsListAction(dispatch);
    dispatch({
      type: GET_EXHIBITION,
      payload: null,
    })
  }, [dispatch, setActivePage]);


 function handleSort() {
   let result = exhibitionsList.filter((ex) => {
    if (search &&  !ex.name?.toLowerCase()?.includes(search.toLowerCase())) {
      return null;
    }
    return ex;
   });
   result = result.sort((a, b) => {
    const aStartDate = a.startDate ? new Date(a.startDate) : new Date();
    const bStartDate = b.startDate ? new Date(b.startDate) : new Date();
    const aEndDate = a.endDate ? new Date(a.endDate) : new Date();
    const bEndDate = b.endDate ? new Date(b.endDate) : new Date();
      switch (activeSort?.value) {
        case "name":
          return b.name.localeCompare(a.name);
        case "endDate":
          return bEndDate > aEndDate ? 1 : -1;
        case "startDate":
          return bStartDate > aStartDate ? 1 : -1;
        default:
          return bStartDate > aStartDate ? 1 : -1;
      }
   })
   setFilteredList(!activeSort?.isAsc ? result: result.reverse());
 }

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (exhibitionsList) {
      handleSort();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[exhibitionsList, search, activeSort]);

  return (
    <div className={styles.container}>

      <header className={styles.header}>
        <h1><Translate id="nav.exhibitions"/></h1>
      </header>

      <main className={styles.content}>
        {exhibitionsList?.length > 0 ?
          <>
            <div className={`${styles.row} ${styles.label} ${styles.actions}`}>
              <InputSearch
                search={search}
                setSearch={setSearch}
                styles={styles}
              />
              <Link to={'/exhibition-create/'}>
                <button 
                  className={styles['btn-edit']}>
                  Ajouter un salon
                </button>
              </Link>
            </div>
            <div className={`${styles.row} ${styles.label} ${styles.sort}`}>
                {sortItems.map((item) => <SortItem
                  key={item.value}
                  item={item}
                  isAsc={item.label === activeSort.label && activeSort.isAsc ? true : false }
                  styles={styles}
                  setActiveSort={setActiveSort}
                />)}
            </div>
            {filteredList?.map((ex) => <ExhibitionsRow key={ex._id} exhibition={ex} styles={styles}/>)}
          </>
          :
          <div style={{ height: '80vh'}}>
            <Loader />
          </div>
        }
        
      </main>

    </div>
  )
}
