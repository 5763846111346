import React, { useCallback, useEffect, useState } from 'react';
import { getExhibitionsListAction } from '../../../actions/exhibitionsActions';
import { getEditListsAction } from '../../../actions/providersActions';
import { SET_ACTIVE_EXHIBITION } from '../../../actions/types';
import chevronIcon from '../../../assets/images/icons/chevron-blue.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import styles from './SelectExhibition.module.scss';

export default function SelectExhibition({ history }) {
  const [ context , dispatch ] = useGlobalContext();
  const { exhibitionsList, activeExhibition }  = context.exhibitions;
  const exhibitions = exhibitionsList?.map((e) => ({ value: e._id, label: e.name, isArchived: e?.isArchived ? true: false })) || [];
  const active = exhibitions.find((e) => e.value === activeExhibition);
  const [ isActive, setIsActive] = useState(false);

  const getData = useCallback(() => {
    getExhibitionsListAction(dispatch);
  }, [dispatch]);

  const getList = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  function handleChangeExhibition(id) {
    history.push('/');
    localStorage.setItem('exhibition' , id);
    setIsActive(false);
    dispatch({
      type: SET_ACTIVE_EXHIBITION,
      payload: id,
    });
  }

  useEffect(() => {
    if (activeExhibition) {
      getList();
    }
  },[activeExhibition, getList])

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
      <div className={styles.selected} >
        <p>{active?.label}</p>
        {exhibitions?.length > 0 &&
          <button
            className={styles.toggle}
            onClick={() => setIsActive(!isActive)}
          >
            <img src={chevronIcon} alt='chevron' />
          </button>
        }
      </div>
      <div className={`${styles.select}`}>
        {exhibitions.filter((e) => !e.isArchived).filter((e) => e.value !== activeExhibition)?.map((ex) => 
        <button
          key={ex.value}
          onClick={() => handleChangeExhibition(ex.value)}
        >
          {ex.label}
        </button>)}
      </div>
    </div>
  )
}
