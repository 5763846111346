export const  GET_USER_LIST = 'GET_USER_LIST',
              ERROR_USERS = 'ERROR_USERS',
              MESSAGE_USERS = 'MESSAGE_USERS',
              GET_USER = 'GET_USER',
              SET_PROVIDER_USER = 'SET_PROVIDER_USER',
              EXPORT_PRESENCES = 'EXPORT_PRESENCES',
              SET_USER_FILTERS = 'SET_USER_FILTERS',
              //AUTH_ACTIONS
              IS_AUTH =  'IS_AUTH',
              USER_AUTH ='USER_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              INIT_AUTH ='INIT_AUTH',
              GET_DASHBOARD ='GET_DASHBOARD',
              SET_FILE='SET_FILE',
              //EXHIBITIONS
              GET_EXHIBITIONS_LIST = 'GET_EXHIBITIONS_LIST',
              SET_ACTIVE_EXHIBITION = 'SET_ACTIVE_EXHIBITION',
              ERROR_EXHIBITIONS = 'ERROR_EXHIBITIONS',
              GET_EXHIBITION = 'GET_EXHIBITION',
              PUT_EXHIBITION = 'PUT_EXHIBITION',
              POST_EXHIBITION = 'POST_EXHIBITION',
              SET_EXHIBITION_DAYS = 'SET_EXHIBITION_DAYS',
              MESSAGE_EXHIBITIONS = 'MESSAGE_EXHIBITIONS',
              SCROLL_ON_EXHIBITIONS = 'SCROLL_ON_EXHIBITIONS',
              EXPORT_EXHIBITIONS_LIST = 'EXPORT_EXHIBITIONS_LIST',

              UPLOAD_COMPANIES="UPLOAD_COMPANIES",

              //PROVIDERS & STANDS ACTIONS
              GET_PROVIDERS_LIST='GET_PROVIDERS_LIST',
              ERROR_PROVIDERS='ERROR_PROVIDERS',
              GET_PROVIDER='GET_PROVIDER',
              RESET_PROVIDER ='RESET_PROVIDER',
              REMOVE_PROVIDER_VIEW='REMOVE_PROVIDER_VIEW',
              GET_EDIT_LISTS= 'GET_EDIT_LISTS',
              POST_PROVIDER = 'POST_PROVIDER',
              MESSAGE_PROVIDER= 'MESSAGE_PROVIDER',
              SCROLL_ON_PROVIDERS = 'SCROLL_ON_PROVIDERS',
              SCROLL_ON_STANDS = 'SCROLL_ON_STANDS',
              EXPORT_PROVIDERS_LIST ='EXPORT_PROVIDERS_LIST',
              EXPORT_PROVIDERS_MAIL = 'EXPORT_PROVIDERS_MAIL',
              EXPORT_PROVIDER_ORDERS = 'EXPORT_PROVIDER_ORDERS',
              SET_PROVIDER_FILTERS = 'SET_PROVIDER_FILTERS',
              //SHOPS_ACTIONS
              GET_SHOPS_LIST = 'GET_SHOPS_LIST',
              GET_SHOP = 'GET_SHOPS',
              ERROR_SHOPS = 'ERROR_SHOPS',
              MESSAGE_SHOPS = 'MESSAGE_SHOPS',
              SCROLL_ON_SHOPS = 'SCROLL_ON_SHOPS',
              EXPORT_SHOPS_LIST = 'EXPORT_SHOPS_LIST',
              SET_SHOP_FILTERS = 'SET_SHOP_FILTERS',
              //ANIMATIONS_ACTIONS
              GET_ANIMATIONS_LIST = 'GET_ANIMATIONS_LIST',
              ERROR_ANIMATIONS = 'ERROR_ANIMATIONS',
              SCROLL_ON_ANIMATIONS = 'SCROLL_ON_ANIMATIONS',
              GET_ANIMATION = 'GET_ANIMATION',
              MESSAGE_ANIMATIONS = 'MESSAGE_ANIMATIONS',
              REMOVE_FILE ='REMOVE_FILE',
              SET_ANIMATION_FILTERS = 'SET_ANIMATION_FILTERS',
              //ASSEMBLY ACTIONS
              GET_ASSEMBLY_LIST = 'GET_ASSEMBLY_LIST',
              ERROR_ASSEMBLY = 'ERROR_ASSEMBLY',
              POST_ANIMATION = 'POST_ANIMATION',
              SCROLL_ON_ASSEMBLY = 'SCROLL_ON_ASSEMBLY',
              GET_ASSEMBLY = 'GET_ASSEMBLY',
              EXPORT_ASSEMBLY = 'EXPORT_ASSEMBLY', 
              PUT_ASSEMBLY = 'PUT_ASSEMBLY',
              SET_ASSEMBLY_FILTERS = 'SET_ASSEMBLY_FILTERS',
              //RECEPTION ACTIONS
              GET_RECEPTION_LIST ='GET_RECEPTION_LIST',
              UPDATE_USER_PRESENCE = 'UPDATE_USER_PRESENCE',
              ERROR_RECEPTION ='ERROR_RECEPTION',
              MESSAGE_RECEPTION ='MESSAGE_RECEPTION',
              GET_BADGE ='GET_BADGE',
              SET_RECEPTION_FILTERS = 'SET_RECEPTION_FILTERS',
              //OFFERS ACTIONS
              GET_OFFERS_LIST = 'GET_OFFERS_LIST',
              MESSAGE_OFFERS = 'MESSAGE_OFFERS',
              UPLOAD_OFFERS = 'UPLOAD_OFFERS',
              UPLOAD_OFFER_IMG = 'UPLOAD_OFFER_IMG',
              ERROR_OFFERS = 'ERROR_OFFERS',
              EXPORT_OFFERS_LIST = 'EXPORT_OFFERS_LIST',
              SCROLL_ON_OFFERS = 'SCROLL_ON_OFFERS',
              GET_OFFER = 'GET_OFFER',
              SET_OFFER_FILTERS = 'SET_OFFER_FILTERS',
              //ORDERS ACTIONS
              GET_ORDERS_LIST = 'GET_ORDERS_LIST',
              ERROR_ORDERS = 'ERROR_ORDERS',
              SCROLL_ON_ORDERS = 'SCROLL_ON_ORDERS',
              SET_ORDERS_FILTERS = 'SET_ORDERS_FILTERS',
              GET_ORDER = 'GET_ORDER',
              GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST',
              MESSAGE_ORDERS ='MESSAGE_ORDERS',
              EXPORT_ORDERS = 'EXPORT_ORDERS',
              VALIDATE_ORDER = 'VALIDATE_ORDER',
              SET_ORDER_FILTERS = 'SET_ORDER_FILTERS',
              //NOTIFICATIONS ACTIONS
              GET_NOTIFICATIONS_LIST = 'GET_NOTIFICATIONS_LIST',
              ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATIONS',
              MESSAGE_NOTIFICATIONS = 'MESSAGE_NOTIFICATIONS',
              POST_NOTIFICATION ='POST_NOTIFICATION'


 