import axios from 'axios';


export const API_URL = process.env.REACT_APP_API_URL;
export const LOCAL_API_URL = "";

export function getData(errorType, url, dispatch, isAuthReq = false, withExhibition  = true) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let Authorization = localStorage.getItem('token');
    let exhibition = localStorage.getItem('exhibition');

    let config = {
      headers: {
        Authorization,
      }
    };

    if (withExhibition) {
      config.headers.exhibition = exhibition;
    }
    
    axios.get(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function getFile(errorType, url, dispatch, isAuthReq = false , type = 'blob') {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let Authorization = localStorage.getItem('token');
    let exhibition = localStorage.getItem('exhibition');

    let config = {
      responseType: type,
      headers: {
        Authorization,
        exhibition,
      }
    };

    axios.get(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function deleteData(errorType, url, dispatch, isAuthReq = true , withExhibition  = true ) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let Authorization = localStorage.getItem('token');
    let exhibition = localStorage.getItem('exhibition');

    let config = {
       headers: {
          Authorization,
        }
    };

    if (withExhibition) {
      config.headers.exhibition = exhibition;
    }

    axios.delete(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}


export function putData(action, errorType,  url, dispatch, data , isAuthReq = false , withExhibition  = true) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let Authorization = localStorage.getItem('token');
    let exhibition = localStorage.getItem('exhibition');

    let config = {
       headers: {
          Authorization,
        }
    };


    if (withExhibition) {
      config.headers.exhibition = exhibition;
    }

    axios.put(requestUrl, data, isAuthReq ? config : null)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
}

export function postData(action, errorType,  url, dispatch, data , isAuthReq = false , withExhibition  = true) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    // var config = {
    //   timeout: 1000 * 60
    // };

    let Authorization = localStorage.getItem('token');
    let exhibition = localStorage.getItem('exhibition');

    let config = {
       headers: {
          Authorization,
        }
    };

    if (withExhibition) {
      config.headers.exhibition = exhibition;
    }

  
    axios.post(requestUrl, data, isAuthReq ? config  : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
}

export function errorHandler(dispatch, error, type) {
  // console.log('Error type: ', type);
  // console.log(error.response)

  if(error.response  && error.response.status === 401 ){
    console.log('Error type: ', type);
    console.log(error.response)

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token')
    }
    dispatch({
      type: 'IS_AUTH',
      payload: false,
    });
    dispatch({
      type: 'ERROR_AUTH',
      payload: error.response.status,
    });
  } else {
    dispatch({
      type: type,
      payload: error.response,
    });
  }
}
