import { format } from 'date-fns';
import React from 'react';
import styles from './Visits.module.scss';
export default function Visits({ visits }) {
  if (visits?.length <= 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className="title">
        <h2>Visites</h2>
      </div>
      <div className={styles.list}>
        {visits.map((v) => <div className={styles.row}>
          <div className={styles.col}>
            <p className="bold text-primary">{v.magasin}</p>
          </div>
          <div className={styles.col}>
            <p>{v.userMagasin.lastName} {v.userMagasin.firstName}</p>
          </div>
          <div className={styles.col}>
            <p>{format(new Date(v.createdAt), "dd/MM/yyy HH:mm")}</p>
          </div>
        </div>)}
      </div>
    </div>
  )
}
