import React from 'react';
import { API_URL } from '../../../../actions/index';
import { Link } from "react-router-dom";

import { calcOfferPrice } from '../../../../utils/utils';
import { putProductAction } from '../../../../actions/offersActions';
import { useGlobalContext } from '../../../context/GlobalContext';

import pending  from "../../../../assets/images/icons/status/pending.svg";
import confirmed from "../../../../assets/images/icons/status/valid.svg";
import refused  from "../../../../assets/images/icons/status/refused.svg";

const icons = {
  pending,
  confirmed,
  refused,
}


export default function OfferRow(props) {
  const { offer } = props;
  const dispatch = useGlobalContext()[1];

  function handleValidation(e) {
    e.preventDefault();
    putProductAction(dispatch, offer._id, { status: 'valid' })
  }
 
  function renderUpdatedLabel() {
    var backgroundColor = "#FF8900";
  
    if(offer.status === "valid") {
      backgroundColor = "#32A860";
    }
    if(offer.status === "correction") {
      backgroundColor = "#F4D300";
    }
    if(offer.status === "refused") {
      backgroundColor = "#FF0009";
    }
    return (
      <div className="updated-label" style={{ backgroundColor,   boxShadow: `0px 0px 2px 2px rgba(236, 162, 92, 0.0)` }}></div>
    );
  
  };

  function renderStatus() {
    var label = "Valider";
    if(offer?.status === "valid") {
      return <div className="company-status">
        <div className={`icon confirmed`}>
          {icons.confirmed && <img src={icons.confirmed} alt='status'/>}
        </div>
      </div>
    }
    if(offer?.status === "correction") {
      return <div className="company-status">
        <div className={`icon pending`}>
          {icons.pending && <img src={icons.pending} alt='status'/>}
        </div>
      </div>
    }
    if(offer?.status === "refused") {
      return <div className="company-status">
      <div className={`icon refused`}>
        {icons.refused && <img src={icons.refused} alt='status'/>}
      </div>
    </div>
    }
    return (
        <button
          type="button"
          onClick={(e) => handleValidation(e)}
        >
          {label}
        </button>
    );
  
  };

  return (
    <Link to={"/offer-view/" + offer._id}>

      <div className="list-row offer">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon product">
            {offer?.image?.path && <img
              src={`${API_URL}/files/${offer.image.path.replace('upload/','')}`}
              alt="product"/>}
          </div>
          <p>
            {offer?.company?.name}
          </p>
        </div>

        <div className="info offer">

          <p>
            {offer.ref}
          </p>

          <p>
            {offer.gencod}
          </p>

          <p>
            {offer.packing}
          </p>

          <p className="price bold text-primary">
            {calcOfferPrice(offer)} €
          </p>

          <div className="status">
            {renderStatus()}
          </div>

        </div>

      </div>

    </Link>
  );
};
