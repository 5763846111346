import React from 'react';

import { format } from 'date-fns';
import Input from '../../../../Partials/form_fields/Input';
import styles from './Form.module.scss';
import HtmlEditor from '../../../../Partials/form_fields/HtmlEditor/HtmlEditor';
import InputFile from '../../../../Partials/form_fields/InputFile/InputFile';
import Checkbox from '../../../../Partials/form_fields/Checkbox'
import { useGlobalContext } from '../../../../context/GlobalContext';
import { putExhibitionAction } from '../../../../../actions/exhibitionsActions';
import StyledSelect from '../../../../Partials/form_fields/StyledSelect/StyledSelect';
import { dimensionsOptions } from '../../../../../constants/constants';
import SwitchField from '../../../../Partials/form_fields/SwitchField';


export default function DescriptionForm({ form, handleChangeForm , width }) {
  const [ context , dispatch ] = useGlobalContext();
  const { exhibition }  = context.exhibitions;
  return (
    <div className={styles.container} style={{ width }}>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <div className={styles.row}>
            <div className={styles['col-2']}>
              <div className={styles.archived}>
                <label>Salon actif</label>
                <div className="container-switch">
                  <SwitchField
                    checked={form.isActive}
                    handleChange={(value) => handleChangeForm({ isActive : value })}
                  >
                    <p>{form.isActive ? "oui": "non"}</p>
                  </SwitchField>
                </div>
              </div>
            </div>
            <div className={styles['col-2']}>
              <div className={styles.archived}>
                <label>Salon archivé</label>
                <div className="container-switch">
                  <SwitchField
                    checked={form.isArchived}
                    handleChange={(value) => handleChangeForm({ isArchived : value })}
                  >
                    <p>{form.isArchived ? "oui": "non"}</p>
                  </SwitchField>
                </div>
              </div>
            </div>
          </div>
          <h2>Info</h2>
          <div className={styles.row}>
            <div className={styles['col-1']}>
            <Input
              label="Nom du salon"
              value={form?.name || ''}
              placeholder={""}
              type={"text"}
              className="input secondary"
              required={false}
              loading={false}
              onChange={(val) => handleChangeForm({ name : val})}
            />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Lieu"
                value={form?.location || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ location : val })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Adresse"
                value={form?.address || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ address : val })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-2']}>
              <Input
                label="Date du début"
                value={form?.startDate ? format(new Date(form?.startDate), 'yyyy-MM-dd') : ''}
                placeholder={"dd-mm-yyyy"}
                type={"date"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ startDate : val })}
              />
            </div>
            <div className={styles['col-2']}>
              <Input
                label="Date de fin"
                value={form?.endDate ? format(new Date(form.endDate), 'yyyy-MM-dd') : ''}
                placeholder={"dd-mm-yyyy"}
                type={"date"}
                min={form?.startDate ? format(new Date(form?.startDate), 'yyyy-MM-dd') : ''}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ endDate : val })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-2']}>
              <StyledSelect
                className="input secondary"
                label="Unité de dimension"
                value={dimensionsOptions?.find((d) => d.value === form?.showroomType)}
                options={dimensionsOptions}
                onChange={(opt) => handleChangeForm({ showroomType : opt.value })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.showroomOption?.displayShowroom}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      displayShowroom: value,
                    }
                  })}
                >
                  <p>
                    Réservation de stand 
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.withProducts}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    withProducts: value,
                  })}
                >
                  <p>
                    Offre salon
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.showroomOption?.displayShowroomSize}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      displayShowroomSize: value,
                    }
                  })}
                >
                  <p>
                    Renseigner la taille du stand
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.showroomOption?.fridge?.isAvailable}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      fridge: {
                        ...form.showroomOption.fridge,
                        isAvailable: value,
                      }
                    }
                  })}
                >
                  <p>
                    Vitrine réfrigérée
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          {form?.showroomOption?.fridge?.isAvailable &&
            <div className={`${styles.row} ${styles.nested}`}>
              <div className={styles['col-2']}>
                <Input
                  label="Prix"
                  value={form?.showroomOption?.fridge?.price || ''}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  required={false}
                  loading={false}
                  onChange={(val) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      fridge: {
                        ...form.showroomOption.fridge,
                        price: parseFloat(val),
                      }
                    }
                  })}
                />
              </div>
              <div className={styles['col-2']}>
                <div className="container-switch">
                  <Checkbox
                    label="Champs obligatoire"
                    className="input secondary"
                    checked={form?.showroomOption?.fridge?.isRequired}
                    onChange={(value) => handleChangeForm({ 
                      ...form,
                      showroomOption: {
                        ...form.showroomOption,
                        fridge: {
                          ...form.showroomOption.fridge,
                          isRequired: value,
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          }
          {form?.showroomOption?.fridge?.isAvailable &&
            <div className={`${styles.row} ${styles.nested} ${styles.border}`}>
              <div className={styles['col-2']}>
                <div className="container-switch">
                  <Checkbox
                    label="Vitrine réfrigérée fournies"
                    className="input secondary"
                    checked={form?.showroomOption?.fridge?.isProvided}
                    onChange={(value) => handleChangeForm({ 
                      ...form,
                      showroomOption: {
                        ...form.showroomOption,
                        fridge: {
                          ...form.showroomOption.fridge,
                          isProvided: value,
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          }
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.showroomOption?.power?.isAvailable}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      power: {
                        ...form.showroomOption.power,
                        isAvailable: value,
                      }
                    }
                  })}
                >
                  <p>
                    Prise éléctrique
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          {form?.showroomOption?.power?.isAvailable &&
            <div className={`${styles.row} ${styles.nested}`}>
              <div className={styles['col-2']}>
                <Input
                  label="Prix"
                  value={form?.showroomOption?.power?.price || ''}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  required={false}
                  loading={false}
                  onChange={(val) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      power: {
                        ...form.showroomOption.power,
                        price: parseFloat(val),
                      }
                    }
                  })}
                />
              </div>
              <div className={styles['col-2']}>
                <div className="container-switch">
                  <Checkbox
                    label="Champs obligatoire"
                    className="input secondary"
                    checked={form?.showroomOption?.power?.isRequired}
                    onChange={(value) => handleChangeForm({ 
                      ...form,
                      showroomOption: {
                        ...form.showroomOption,
                        power: {
                          ...form.showroomOption.power,
                          isRequired: value,
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          }
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form?.showroomOption?.chairs?.isAvailable}
                  handleChange={(value) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      chairs: {
                        ...form.showroomOption.chairs,
                        isAvailable: value,
                      }
                    }
                  })}
                >
                  <p>
                    Chaise fournies
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          {form?.showroomOption?.chairs?.isAvailable &&
            <div className={`${styles.row} ${styles.nested}`}>
              <div className={styles['col-2']}>
                <Input
                  label="Prix"
                  value={form?.showroomOption?.chairs?.price || ''}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  required={false}
                  loading={false}
                  onChange={(val) => handleChangeForm({ 
                    ...form,
                    showroomOption: {
                      ...form.showroomOption,
                      chairs: {
                        ...form.showroomOption.chairs,
                        price: parseFloat(val),
                      }
                    }
                  })}
                />
              </div>
              <div className={styles['col-2']}>
                <div className="container-switch">
                  <Checkbox
                    label="Champs obligatoire"
                    className="input secondary"
                    checked={form?.showroomOption?.chairs?.isRequired}
                    onChange={(value) => handleChangeForm({ 
                      ...form,
                      showroomOption: {
                        ...form.showroomOption,
                        chairs: {
                          ...form.showroomOption.chairs,
                          isRequired: value,
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          }
          {/* <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.chairsAvailable}
                  handleChange={(value) => handleChangeForm({ chairsAvailable : value })}
                >
                  <p>
                    Chaise fournies
                  </p>
                </SwitchField>
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.block}>
          <h2>Contacts</h2>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Nom du contact"
                value={form?.contact?.name || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , name: val }})}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Téléphone"
                value={form?.contact?.tel || ''}
                placeholder={""}
                type={"tel"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , tel: val }})}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Email"
                value={form?.contact?.email || ''}
                placeholder={""}
                type={"email"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , email: val }})}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <h2>Description</h2>
           <HtmlEditor
            defaultValue={exhibition?.description}
            onChange={(val) => handleChangeForm({ description : val})}
           />
        </div>
        {form?._id &&
          <div className={styles.block}>
            <h2>Image de présentation</h2>
            <InputFile
              file={form?.cover || ''}
              id="cover"
              label="Télécharger votre image de présentation"
              onChange={(val) => putExhibitionAction(dispatch, exhibition?._id, { cover: val })}
            />
          </div>
        }
      </div>
    </div>
  )
}
