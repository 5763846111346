import React , { useState , useCallback , useEffect } from 'react';
import { Link , useParams } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import history from "../../../utils/history";
import { useGlobalContext } from '../../context/GlobalContext';
import * as _ from 'lodash'; 

import { deleteOrderAction, getOrderAction , putOrderAction, validateOrdersAction } from '../../../actions/ordersActions';
import { calcOfferPrice , calcOrderTotal } from '../../../utils/utils';

import ProductDetailRow from './Orders_Partials/ProductDetailRow';

import pendingIcon from './images/pending.svg';
import deleteIcon from './images/delete.svg';
import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

export default function OrderView({ setActivePage }) {
  const params = useParams();
  const [ context, dispatch ] = useGlobalContext();

  const [ filtredList , setFiltredList ] = useState();
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByType , setSortByType ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByQantity , setSortByQantity ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);

  const getOrder = useCallback(
    () => {
      getOrderAction(dispatch , params.id)
    },
    [dispatch , params.id],
  );

  async function deleteOrder(id) {
    const res = await deleteOrderAction(dispatch, context.orders.order?._id);
    if (res) {
      history.push('/orders');
    }
  }

  useEffect(() => {
    setActivePage("orders")
    getOrder();
  }, [getOrder , setActivePage]);

  useEffect(() => {
    if (context.orders.order && context.orders.order.products ) {
      setFiltredList(setProductQuantity(context.orders.order.products));
    }
    // eslint-disable-next-line 
  }, [context.orders.order]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByType) {
        setFiltredList(filtredList.sort((a ,b )=> (a.type > b.type)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.type > b.type)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByType]);

  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByQantity) {
        setFiltredList(filtredList.sort((a ,b )=> (a.quantity > b.quantity)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.quantity > b.quantity)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByQantity]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (calcOfferPrice(a) > calcOfferPrice(b)) ? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (calcOfferPrice(a) > calcOfferPrice(b))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  function setProductQuantity(productArray) {
    let gencodList = _.uniqBy(productArray , "gencod");
    gencodList.forEach(product => {
      var foundOthers = productArray.filter(item => item.gencod === product.gencod );
      product.quantity = foundOthers.length
    });
    return gencodList;
  }
  
  function renderProductsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map(( offer , i) => <ProductDetailRow key={i} offer={offer}/>);
      }
    };
  };

  return (
    <div className="page-container">
      <header className="page-header">

        <div className="icon">
          <img src={providerIcon} alt="icon-provider"/>
        </div>

        <h1>
          {context.orders && context.orders.order && context.orders.order && context.orders.order.products && context.orders.order.products.length && context.orders.order.company &&
          <>
            Commande de {context.orders.order.company.name} pour {context.orders.order.products[0] && context.orders.order.products[0].company && context.orders.order.products[0].company.name}<br/> 
            <span className="text-primary">Total : {calcOrderTotal(context.orders.order.products)} €</span>
          </>
          }
        </h1>

      </header>

      <main className="page-content">

        {context.orders.order && context.orders.order.status && context.orders.order.status  !== "confirmed" ?
          <button
            className="btn-save"
            onClick={() => validateOrdersAction(dispatch , { user : context.auth.user._id , order: context.orders.order._id }) }
            style={{ float: "right" , marginBottom: 10, width : 100}}>Valider</button>
          :
          <h1 className="text-success" style={{ float: "right" , marginBottom: 10 }} >Commande validé</h1>
        }

        <Link to={"/orders"} className="btn-edit mb-10">
          <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
        </Link>

        {context?.orders?.order?._id &&
          <div className='status'>
            {context.orders.order.status === "confirmed" &&
              <button
                type='button'
                onClick={() => putOrderAction(dispatch, { status: "pending" } ,  context.orders?.order?._id)}
              >
                <img src={pendingIcon} alt="Repasser en attente" />
                Repasser en attente
              </button>
            }
            {context.orders.order.status !== "confirmed" &&
              <button
                type='button'
                onClick={() => history.push(`/edit-order/${context?.orders?.order?._id}`)}
              >
                <img src={pendingIcon} alt="modifier la commande" />
                Modifier
              </button>
            }
            <button
              type='button'
              className='delete'
              onClick={() => deleteOrder()}
            >
              <img src={deleteIcon} alt="Supprimer la commande" />
              Supprimer la commande
            </button>
          </div>
        }

        <div className="filters">
          <div
            className="filter"
            onClick={() => setSortByType(!sortByType)}>
            <span>Famille de produit</span>
            <img src={filterIcon} className={sortByType? "rotate180": ""} alt="filter"/>
          </div>
          <div
            className="filter"
            style={{ transform: 'translateX(10px)'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Segment</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(30px)'}}
            onClick={() => setSortByRef(!sortByRef)}>
            <span>Référence</span>
            <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(45px)'}}
            onClick={() => setSortByGencode(!sortByGencode)}>
            <span>Gencod</span>
            <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(60px)'}}
            onClick={() => setSortByPack(!sortByPack)}>
            <span>Qté en colis</span>
            <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(40px)'}}
            onClick={() => setSortByQantity(!sortByQantity)}>
            <span>Qté commandée</span>
            <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByTotal(!sortByTotal)}>
            <span>Montant total</span>
            <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
          </div>

        </div>
        {renderProductsList()}
      </main>
    </div>
  );
}
