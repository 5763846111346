import React from "react";
import { Link } from "react-router-dom";

import shopsIcon  from "../../../../assets/images/icons/shops_icon.svg";
import contact  from "../../../../assets/images/icons/contact_primary.svg";
import contactGrey  from "../../../../assets/images/icons/contact_grey.svg";
import flag  from "../../../../assets/images/icons/flag.svg";
import userSlash from "../../../../assets/images/icons/users-slash.svg";

// import pending  from "../../../../assets/images/icons/status/pending.svg";
import valid  from "../../../../assets/images/icons/status/valid.svg";
// import refused  from "../../../../assets/images/icons/status/refused.svg";

import { updateShopAction } from '../../../../actions/shopsActions';
import { useGlobalContext } from "../../../context/GlobalContext";

// const icons = {
//   pending,
//   valid,
//   refused,
// }

export default function ShopRow({ shop, dashboard }) {
  const dispatch = useGlobalContext()[1];
  function renderUpdatedLabel() {
    var updatedDate = new Date(shop.updatedAt);
    var today = new Date()
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  }

  let adherent = shop?.users?.find((u) => u?.profile?.function === 'ADHERENT');

  const updateShopPresence = (e) => {
    e.preventDefault();
    updateShopAction(dispatch, {
      id: shop?._id,
      presence: !shop.presence,
    })
  }

  function renderStatus(users) {
    const isValid = users.length > 0 && users.every((u) => u.status === "valid") ? true : false;
    return (
      <div className="company-status">
        <div className={`icon ${isValid ? 'valid': ''}`}>
          <img src={valid} alt='status'/>
        </div>
      </div>
    );
  }


  return (
    <Link to={"/shop-view/" + shop._id}>
      <div className="list-row" >
        {renderUpdatedLabel()}
        <div className="name">
          <div className="icon">
            <img src={shopsIcon} alt="icon-provider"/>
          </div>
          <p>
            {shop.name}
          </p>
        </div>
        <div className={`info shop ${dashboard ? "dashboard" : ""}`}>
          <div className="contacts">
            {shop?.users?.length > 0 ?
              <>
                <div className="picto">
                  <p>{shop?.users?.filter( u => u?.presence?.length > 0)?.length}</p> <img src={contact} alt="icon-contact"/>
                </div>
                <div className="picto">
                  <p style={{ marginLeft: 10 }}>{shop?.users?.filter( u => u?.presence?.length === 0)?.length}</p> <img src={contactGrey} alt="icon-contact"/>
                </div>
              </>
            :
              <div className="picto not-active">
                <p>{0}</p> <img src={contactGrey} alt="icon-contact"/>
              </div>
            }
          </div>
          {!dashboard &&
            <p className="centrale">
              {shop.centrale}
            </p>
          }
          {!dashboard && shop?.location?.city &&
            <p className="city">
              {shop?.location?.city}
            </p>
          } 

          {shop?.users?.length > 0 ?
            <>
              {adherent?.presence?.length > 0 ?
                <>
                  <div className="picto">
                    <img src={flag} alt="icon-flag"/>
                    <p style={{ width: 30 , marginLeft: 10 }}>oui</p>
                  </div>
                </>
                :
                <>
                  <div className="picto not-active">
                    <img src={flag} alt="icon-flag"/>
                    <p style={{ width: 30 , marginLeft: 10 }}>non</p>
                  </div>
                </>
              }
              </>
            :
              <>
                <div className="picto">
                  {shop.presence && <img src={userSlash} className="user-slash" alt="icon-flag"/>}
                </div>
              </>
            }

            <div
              className="btn-is-here"
              onClick={(e) => updateShopPresence(e)}>
              <div className={`picto ${shop?.presence? '':'not-active'}`}>
                <img src={flag} alt="icon-flag"/>
                <p style={{ width: 30 , marginLeft: 10 }}>{shop?.presence ? 'oui': 'non'}</p>
              </div>
            </div>
            {renderStatus(shop?.users)}
        </div>
      </div>
    </Link>
  )
}
