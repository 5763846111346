import { getData , putData , postData , deleteData } from './index';

import {
  GET_USER_LIST,
  GET_USER,
  ERROR_USERS,
  SET_PROVIDER_USER,
  MESSAGE_USERS,
  EXPORT_PRESENCES,
  UPDATE_USER_PRESENCE
} from "./types"

export async function getUsersListAction(dispatch){
  let url = "/user"
  let usersList;
  await getData( ERROR_USERS, url, dispatch , true).then((response)=>{
    usersList = response.users
  })
  
  dispatch({
    type : GET_USER_LIST,
    payload : usersList
  })
}

export async function getUserAction(dispatch , id){
  let url = "/user/" + id
  let user;
  await getData( ERROR_USERS, url, dispatch , true).then((response)=>{
    user = response.user
  })

  dispatch({
    type : GET_USER,
    payload : user
  })
}

export async function resetUserAction(dispatch){
  dispatch({
    type : GET_USER,
    payload : null
  })
}

export async function uptadeUserPresenceAction(dispatch , id , date ){
  let url = `/user/scan/${id}/?date=${date}`
  let user;
  await getData( ERROR_USERS, url, dispatch , true).then((response)=>{
    user = response.user
  })
  let exhibitionId = localStorage.getItem('exhibition');
  const exhibition = user.exhibitions.find((ex) => ex.exhibition === exhibitionId);
  if (exhibition.isHere) {
    user.isHere = exhibition.isHere;
  }
  if (exhibition.presence) {
    user.presence = exhibition.presence;
  }
  dispatch({
    type : GET_USER,
    payload : user,
  });
  dispatch({
    type : UPDATE_USER_PRESENCE,
    payload : user,
  });
}

export async function updateUserAction(dispatch , data , type ){
  let url = "/user/" + data.id 
  let contactUpdated;
  await putData( SET_PROVIDER_USER , ERROR_USERS, url, dispatch, data , true).then((response)=>{
    contactUpdated = response;
  })
  dispatch({
    type : GET_USER,
    payload : contactUpdated.user
  })
  dispatch({
    type : MESSAGE_USERS,
    payload : contactUpdated.message
  });
}

export async function createUserAction(dispatch , data , type ){
  let url = "/user"
  let contactCreated;
  await postData( SET_PROVIDER_USER , ERROR_USERS, url, dispatch, data , true).then((response)=>{
    contactCreated = response;
  })
  dispatch({
    type : MESSAGE_USERS,
    payload : contactCreated.message
  });
}


export async function deleteUserAction(dispatch , id , type ){
  let url = "/user/" + id ;
  let usersDeleted;
  await deleteData( ERROR_USERS, url, dispatch , true).then((response)=>{
    usersDeleted = response;
  })
  dispatch({
    type : MESSAGE_USERS,
    payload : usersDeleted.message
  });
}


export async function exportPresencesAction(dispatch , obj ){
  let url = "/export/presence"
  let exportList;
  await postData(EXPORT_PRESENCES , ERROR_USERS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_PRESENCES,
    payload : exportList
  })
}


