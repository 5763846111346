import FileSaver from 'file-saver';
import { getData, getFile, postData, putData } from './index';

import {
  GET_OFFERS_LIST,
  ERROR_OFFERS,
  GET_OFFER,
  MESSAGE_OFFERS,
  UPLOAD_OFFERS,
  EXPORT_OFFERS_LIST
} from "./types"

export async function getOffersListAction(dispatch){
  let url = "/product"
  let offersList;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offersList = response.products
  })
  dispatch({
    type : GET_OFFERS_LIST,
    payload : offersList
  })
}

export async function getOfferAction(dispatch , id){
  let url = "/product/" + id 
  let offer;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offer = response.product
  })
  dispatch({
    type : GET_OFFER,
    payload : offer
  })
}

export async function postProductAction(dispatch , data){
  let url = "/product/" 
  let productCreated;
  await postData(GET_OFFER , ERROR_OFFERS , url, dispatch , data, true , true ).then((response)=>{
   productCreated = response?.product;
  });
  if (productCreated) {
    dispatch({
      type : GET_OFFER,
      payload : productCreated
    })
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Le produit a été crée"
    });
  }
}

export async function putProductAction(dispatch , id, data){
  let url = "/product/" + id 
  let productUpdated;
  await putData( GET_OFFER , ERROR_OFFERS, url, dispatch, data , true , true).then((response)=>{
    productUpdated = response?.product;
  })
  if (productUpdated) {
    dispatch({
      type : GET_OFFER,
      payload : productUpdated
    })
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Le produit a été modifié"
    });
  }
}


export async function uploadProductsAction(dispatch , data){
  const type = data.get('type');
  let url = "/product/upload"
  if(type === "image") {
    url = "/product/upload/images"
  }
  let productUpdated;
  await postData(UPLOAD_OFFERS , ERROR_OFFERS , url, dispatch , data, true ).then((response)=>{
   productUpdated = response;
  });
  if (productUpdated) {
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Les produits ont été mise à jour"
    });
  }
}

export async function exportOffersListAction(dispatch , obj ){
  let url = "/export/product"
  let exportList;
  await postData(EXPORT_OFFERS_LIST , ERROR_OFFERS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_OFFERS_LIST,
    payload : exportList
  })
}

export async function exportProviderOffersById(dispatch , id , name ){
  let url = `/product/export/company/${id}`
  let exportList;
  await getFile( ERROR_OFFERS, url, dispatch , true ).then((response)=> {
    exportList = response;
    var blob = new Blob([response], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
    FileSaver.saveAs(blob, `offres-${name}.xlsx`);
  });
  return exportList;
}