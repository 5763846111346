import React , { useCallback , useEffect } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getUserAction } from '../../../actions/usersActions';
import { getEditListsAction, getProvidersListAction } from '../../../actions/providersActions';

import ContactRow from '../../Partials/ContactRow';
import { getShopsListAction } from '../../../actions/shopsActions';

export default function UserEdit({ setActivePage , match, history }) {

  const [ context , dispatch ] = useGlobalContext();

  const { user, error } = context.users;
  const { lists } = context.providers;
  const providersList = context?.providers?.providersList || [];
  const shopsList = context?.shops?.shopsList || [];
  const companiesOptions = [...providersList, ...shopsList];

  var companyId = match.params.id;
  const categoriesOptions = lists?.categories?.map(category => ({ label: category , value: category }));
  const activitiesOptions = lists?.activities?.map(domain => ({ label: domain , value: domain }));
  const daysOptions = lists?.days || [];

  const getUser = useCallback(() => {
    getUserAction(dispatch, match.params.id);
    getProvidersListAction(dispatch);
    getShopsListAction(dispatch);
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });
    dispatch({
      type:'ERROR_USERS',
      payload: null
    })
  }, [dispatch , match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getUser();
    setActivePage("users");
    getEditLists();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (context.users.message === "User successfully updated!") {
      successUpdate();
    }
    if (context.users.message === "User successfully removed!") {
      successRemove();
    }
    // eslint-disable-next-line
  }, [context.users.message]);

  function successRemove() {
    history.push("/users")
    store.addNotification({
      message: "Utilisateur supprimé",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successUpdate() {
    store.addNotification({
      message: "Utilisateur mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  return (
    <div className="page-container">
      <header className="page-header">
        <h1><Translate id="nav.users"/></h1>
      </header>
      <main className="page-content">
        <Link to={"/users"} className="btn-edit mb-10">
          <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
        </Link>
      {user &&
        <ContactRow 
          companyId={companyId}
          dispatch={dispatch}
          contact={user}
          error={error}
          page={'user'}
          daysOptions={daysOptions}
          companiesOptions={companiesOptions}
          activitiesOptions={activitiesOptions}
          categoriesOptions={categoriesOptions} />          
      }
      </main>
    </div>
  )
}
