import React , { useState , useCallback , useEffect , useMemo } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';
import { Link } from 'react-router-dom'

import { getOrdersListAction , exportOrdersAction } from '../../../actions/ordersActions';
import { useGlobalContext } from '../../context/GlobalContext';

import OrderRow from './Orders_Partials/OrderRow';
import ExportExcel from '../../Partials/ExportExcel';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { EXPORT_ORDERS, SET_ORDERS_FILTERS } from '../../../actions/types';
import { ordersSort } from '../../../reducers/ordersReducer';
import { calcOrderTotal } from '../../../utils/utils';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OrdersList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const [ filtredList , setFiltredList ] = useState();
  const [ exportIsLoading , setExportIsLoading] = useState(false);

  const { filters } = context.orders;

  const getOrdersList = useCallback(() => {
    setActivePage("orders");
    window.scrollTo(0, context.orders.scrollOrders)
    getOrdersListAction(dispatch);
    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

    dispatch({
      type : 'GET_ORDER',
      payload : null
    });

    dispatch({
      type : 'EXPORT_ORDERS',
      payload : null
    });

  }, [context.orders.scrollOrders, dispatch, setActivePage]);

  function handleChangeFilters(obj) {
    return dispatch({
      type: SET_ORDERS_FILTERS,
      payload: {...filters, ...obj }
    });
  }

  useEffect(() => {
    getOrdersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.orders.ordersList.length > 0 ) {
      let list = context.orders.ordersList;
      list.forEach(item => {
        var total = 0
        item.products.forEach(product => total += product.price )
        item.total = total
      })
      setFiltredList(list.sort(function(a, b) {
        var dateA = new Date(a.updatedAt)
        var dateB = new Date(b.updatedAt)
        return dateA.getTime() - dateB.getTime()
      }).reverse());
    }
    // eslint-disable-next-line 
  }, [context.orders.ordersList])

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_ORDERS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (context.orders.ordersList) {
      var newList = context.orders.ordersList.filter(
        (order) => order.company.name.toLowerCase().includes(filters.search.toLowerCase())
        || order?.products.find((p) => p.gencod?.toLowerCase()?.includes(filters.search.toLowerCase()))
        )
        .map((order) => ({...order, total: parseFloat(calcOrderTotal(order.products))}));

      if (filters?.providers?.length) {
        filters.providers.forEach(option => {
          newList = newList.filter(order => {
            var found = order.products.find(product => product.company.name === option.value)
            if (found) return true;
            else return false
          });
        })
      }
      if (filters?.shops?.length) {
        filters.shops.forEach(option => {
          newList = newList.filter(order => {
            var found = order.company.name === option.value
            if (found) return true;
            else return false;
          });
        })
      }
      
      setFiltredList(newList);
    } 
    // eslint-disable-next-line 
  }, [filters, context.orders.ordersList]);

  useEffect(() => {
    if (filtredList) {
      if (filters.sort.alpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [filters.sort.alpha]);

  useEffect(() => {
    if (filtredList) {
      if (filters.sort.status) {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  },  [filters.sort.status]);

  useEffect(() => {
    if (filtredList) {
      if (filters.sort.total) {
        setFiltredList(filtredList.sort((a ,b )=> (a.total > b.total)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.total > b.total)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [filters.sort.total]);

  useEffect(() => {
    if (filtredList) {
      if (filters.sort.productsCount) {
        setFiltredList(filtredList.sort((a ,b )=> (a.products.length > b.products.length)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.products.length > b.products.length)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [filters.sort.productsCount]);

  function renderExportBtn() {
    if (context.orders.exportList && context.orders.exportList.fileName) {
      return <ExportExcel
      callBack={() => {
        dispatch({
          type : EXPORT_ORDERS,
          payload : null
        });
        setExportIsLoading(false);
      }}
        fileName={context.orders.exportList.fileName}
        dataToExport={context.orders.exportList.dataToExport}/>
    } else {
      return <IconBtn
        label={"Exporter les commandes"}
        icon="export"
        isLoading={exportIsLoading}
        handleClick={() => {
          exportOrdersAction(dispatch);
          setExportIsLoading(true);
        }} 
      />
    }
  }

  
  function renderOrdersList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map(( order , i) => <OrderRow key={i} order={order}/>);
      } else if (filters.search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (filters?.shops?.length && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (filters?.providers?.length && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };;
    };
  };

  function renderSelectProviders() {
    var options = []
    if (context.orders.ordersList) {
      context.orders.ordersList.forEach(order => {
        order.products.forEach( product => {
          var option = {
            label: product.company.name,
            value: product.company.name
          }
          options.push(option)
        })
      })
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        onChange={(val) => handleChangeFilters({ providers: val })}
        value={filters.providers}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par fournisseur"
        />
    )
  }

  function renderSelectClients() {
    var options = []
    if (context.orders.ordersList) {
      options = context.orders.ordersList.map(item => ({
        label: item.company.name,
        value: item.company.name
       }))
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        onChange={(val) => handleChangeFilters({ shops: val })}
        value={filters.shops}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par magasins"
        />
    )
  }

  return useMemo(()=> { return (

    <div className="page-container">
      <header className="page-header space-between">
        <h1>Les commandes</h1>
        <div className="btn-container export">
          {renderExportBtn()}
          <Link to={'/create-order'}>
            <button
              className="btn-edit"
              style={{ height:36 , paddingLeft: 20 , paddingRight: 20 }}>
              Créer une commande
            </button>
          </Link>
        </div>
      </header>

      <main className="page-content">

        <div className="row search-container">
          <div className="col-3 input">
            <input placeholder="Recherche" value={filters.search} onChange={(e) => handleChangeFilters({ search: e.target.value })} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="col-3 select">
            {renderSelectProviders()}
          </div>
          <div className="col-3 select">
            {renderSelectClients()}
          </div>
        </div>

        <div className="filters">

          <div
            className="filter"
            onClick={() => handleChangeFilters({ sort: {...ordersSort, alpha: !filters.sort.alpha } })}>
            <span>Magasin</span>
            <img src={filterIcon} className={filters.sort.alpha ? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(5%)'}}
            onClick={() => handleChangeFilters({ sort: {...ordersSort, alpha: !filters.sort.alpha } })}>
            <span>Fournisseur</span>
            <img src={filterIcon} className={filters.sort.alpha ? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(10%)'}}
            onClick={() => handleChangeFilters({ sort: {...ordersSort, productsCount: !filters.sort.productsCount } })}>
            <span>Nombre d’articles</span>
            <img src={filterIcon} className={filters.sort.productsCount ? "rotate180": ""} alt="filter"/>
          </div>

          <div
            style={{ transform: 'translateX(-42%)'}}
            className="filter">
            {/* onClick={() => setSortByAlpha(!sortByAlpha)}*/}
            <span>Types de produits</span>
            {/*<img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-70%)'}}
            onClick={() => handleChangeFilters({ sort: {...ordersSort, total: !filters.sort.total } })}>
            <span>Montant total</span>
            <img src={filterIcon} className={filters.sort.total ? "rotate180": ""} alt="filter"/>
          </div>

          <div
            style={{ transform: 'translateX(-50%)'}}
            className="filter">
            {/* onClick={() => setSortByStatus(sortByStatus)}> */}
            <span>status</span>
            {/* <img src={filterIcon} className={sortByStatus? "rotate180": ""} alt="filter"/> */}
          </div>

        </div>

        {renderOrdersList()}

      </main>

    </div>
  )
  // eslint-disable-next-line
  }, [filtredList , filters, context.providers.lists.products , context.orders.exportList]);
};
