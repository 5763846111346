import React , { useState , useCallback , useEffect , useMemo } from 'react';
import { store } from 'react-notifications-component';
// import { Translate } from "react-localize-redux";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';

import { exportOffersListAction, getOffersListAction, uploadProductsAction } from '../../../actions/offersActions';
import { useGlobalContext } from '../../context/GlobalContext';
import history from "../../../utils/history";

import OffersRow from './Offers_Partials/OffersRow';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import Modale from '../../Partials/Modale/Modale';
import UploadOfferModal from '../../Partials/UploadOfferModal/UploadOfferModal';
import { EXPORT_OFFERS_LIST, MESSAGE_OFFERS, SET_OFFER_FILTERS } from '../../../actions/types';
import ExportExcel from '../../Partials/ExportExcel';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OffersList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const [ filtredList , setFiltredList ] = useState();

  const { message, exportOffersList } = context?.offers;

  const { search , selectedProducts } = context?.offers.filters;

  const [ updateOffers , setUpdateOffers ] = useState(null);
  
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByStatus , setSortByStatus ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);
  const [ exportIsLoading , setExportIsLoading] = useState(false);

  const getOffersList = useCallback(() => {

    getOffersListAction(dispatch);

    dispatch({
      type : 'GET_OFFER',
      payload : null
    });

    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });
    setActivePage("offers");

  }, [dispatch, setActivePage]);

  useEffect(() => {
    getOffersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(context.offers.scrollOffers && context.offers.offersList) {
      window.scrollTo(0, context.offers.scrollOffers)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.offers.offersList]);

  useEffect(() => {
    if (context.offers.offersList) {
      let list = context.offers.offersList || [];
      setFiltredList(list);
    }
    // eslint-disable-next-line 
  }, [context.offers.offersList])

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_OFFERS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (context.offers.offersList) {
      var newList = context.offers.offersList.filter((offer) => 
      offer?.company?.name?.toLowerCase()?.includes(search.toLowerCase())
      || offer?.gencod?.toLowerCase()?.includes(search.toLowerCase())
      || offer?.description?.toLowerCase()?.includes(search.toLowerCase())
      || offer?.product?.toLowerCase()?.includes(search.toLowerCase())
      );
      if (selectedProducts && selectedProducts.length > 0) {
        var filtredByProduct = [];
        selectedProducts.forEach(option => {
          newList.forEach(provider => {
            var found = provider.type === option.value;
            if (found) filtredByProduct.push(provider);
          });
        })
        setFiltredList(_.uniqBy(filtredByProduct, "_id"))
      } else {
        setFiltredList(newList);
      }
    } 
    // eslint-disable-next-line 
  }, [search , selectedProducts ,context.offers.offersList]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByStatus) {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByStatus]);

  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  useEffect(() => {
    if (message) {
      successNotif(message);
      getOffersList();
    }
  });

  function successNotif(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : MESSAGE_OFFERS,
      payload : null,
    });
  }
  
  function renderOffersList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map(( offer , i) => <OffersRow key={i} offer={offer}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  function renderSelectProduct() {
    var options = []
    if (context.offers.offersList) {
      options = context.offers.offersList.map(item => ({
        label: item.type,
        value: item.type
       }))
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={selectedProducts}
        onChange={(val) => dispatch({
          type: SET_OFFER_FILTERS,
          payload: {
            ...context?.offers.filters,
            selectedProducts: val,
          }
        })}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par type de produits"
        />
    )
  };

  function renderExportOffers() {
    if (exportOffersList?.fileName) {
      return <ExportExcel
        callBack={() => {
          dispatch({
            type : EXPORT_OFFERS_LIST,
            payload : null
          });
          setExportIsLoading(false);
        }}
        fileName={exportOffersList.fileName}
        dataToExport={exportOffersList.dataToExport}/>
    } else {
      return <IconBtn
        label={"Exporter les offres"}
        icon="export"
        isLoading={exportIsLoading}
        handleClick={() => {
          exportOffersListAction(dispatch , {});
          setExportIsLoading(true);
        }} 
      />
    }
  }


  return useMemo(()=> { return (
    <div className="page-container">
      <header className="page-header"
        style={{ 
          display: 'flex',
          justifyContent: 'space-between',
         }}
      >
        <h1>Offres salon</h1>
      </header>

      <main className="page-content">

        <div className="row">
          <div className="col-3 input">
            <input placeholder="Recherche"
              value={search}
              onChange={(e) => dispatch({
                type: SET_OFFER_FILTERS,
                payload: {
                  ...context?.offers.filters,
                  search: e.target.value,
                }
              })}
            />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="col-3"
            style={{ marginTop: 4 }}
          >
            {renderSelectProduct()}
          </div>
          <div className="col-2"
            style={{ display: 'flex', justifyContent: "flex-end" , margin:0 }}
          >
            <IconBtn
              label={"Mettre à jour les images"}
              icon="picture"
              handleClick={() => setUpdateOffers("image")} 
            />
            <IconBtn
              label={"Mettre à jour"}
              icon="refresh"
              handleClick={() => setUpdateOffers("products")} 
            />
            {renderExportOffers()}
            <button 
              className="btn-edit hide-tablet"
              style={{ height:36 , paddingLeft: 15 , paddingRight: 15 }}
              onClick={() => history.push('/offer-create')} 
            >
              Nouveau produit
            </button>
          </div>

        </div>

        <div className="filters">

          <div
            className="filter"
            style={{ minWidth: '15%'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du fournisseur</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-35px)'}}
            onClick={() => setSortByRef(!sortByRef)}>
            <span>Référence</span>
            <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-25px)'}}
            onClick={() => setSortByGencode(!sortByGencode)}>
            <span>Gencod</span>
            <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-15px)'}}
            onClick={() => setSortByPack(!sortByPack)}>
            <span>Qté en colis</span>
            <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByTotal(!sortByTotal)}>
            <span>Montant total</span>
            <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByStatus(!sortByStatus)}>
            <span>Validation</span>
            <img src={filterIcon} className={sortByStatus ? "rotate180": ""} alt="filter"/>
          </div>

        </div>

        {renderOffersList()}

      </main>
      <Modale isOpen={updateOffers}>
        {updateOffers && <UploadOfferModal
          type={updateOffers}
          label={updateOffers === "products" ? "Mettre à jour les produits" : "Mettre à jour les images produits"}
          onSubmit={(data) => uploadProductsAction(dispatch, data)}
          close={() => setUpdateOffers(null)}
        />}
      </Modale>

    </div>
  )
  // eslint-disable-next-line
  }, [filtredList , sortByAlpha , sortByStatus , sortByRef , sortByGencode , sortByPack , updateOffers, sortByTotal , search, exportOffersList ]);
};
