import React, { useState , useEffect } from 'react';
import checkIcon from '../../../assets/images/icons/check.svg';
import checkedIcon from '../../../assets/images/icons/checked.svg';

export default function Checkbox({ checked, onChange, className, label }) {
  const [ value , setValue ] = useState(false)

  function handleChange(val) {
    onChange(val);
    setValue(val)
  }

  useEffect(() => {
    setValue(checked)
  }, [checked])

  return (
    <div className={className}>
      <label className="bold">{label}</label>
        <div className={`checkbox ${value && " checked"}`}
          onClick={() => handleChange(!value)}
        >
          <img src={value ? checkedIcon : checkIcon } alt='checkbox' />
          <p>{value ? "oui": "non"}</p>
        </div>
    </div>
  )
}
