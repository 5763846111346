import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProvidersListAction } from '../../../actions/providersActions';
import { getShopsListAction } from '../../../actions/shopsActions';

import selectStyle from '../../Partials/selectStyle';
import filterIcon from "../../../assets/images/icons/filters.svg";

import ProductsRow from "./Orders_Partials/ProductsRow";

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import { getOrderAction, getProductsAction , postOrderAction, putOrderAction } from '../../../actions/ordersActions';
import Input from '../../Partials/form_fields/Input';

export default function OrderEdit({ setActivePage }) {

  var history = useHistory();

  const [ context , dispatch ] = useGlobalContext();

  const [ form , setForm ] = useState({
    user: context?.auth?.user?._id,
    company: null,
    domain:  null,
    provider: null,
    products: []
  });

  const id = useParams()?.id;

  const providersList = context?.providers?.providersList || [];
  const shopsList = context?.shops?.shopsList || [];
  const order = context.orders?.order;

  const providersOptions = providersList.map((item) => ({ label: item.name , value: item._id }));
  const shopsOptions = shopsList.map(item => ({ label: item.name , value: item._id, domains: item.domains }));
  const domainsOptions = shopsOptions?.find(
    (s) => s.value === form.company)?.domains?.map(
      (item) => ({ label: item.label, value: item.code }));

  const provider = providersOptions?.find((d) => d.value === form?.provider);
  
  const [ filtredList , setFiltredList ] = useState();
  const [search, setSearch ] = useState('');

  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);

  const getProvidersList = useCallback(() => {
    window.scrollTo(0,0);
    setActivePage("orders")
    getProvidersListAction(dispatch);
    getProductsAction(dispatch);
    getShopsListAction(dispatch);
    if (id) {
      getOrderAction(dispatch, id)
    }
  }, [dispatch, setActivePage, id]);

  useEffect(() => {
      getProvidersList();
  }, [getProvidersList]);

  useEffect(() => {
    if (order?._id && order?.updatedAt) {
      const orderForm = {
        ...order,
        company: order?.company?._id || order?.company,
        domain: order?.domain,
        products: order?.products.map(item => item._id),
        provider: order?.products?.length > 0 ? order.products[0].company?._id : null
      }
      setForm(orderForm);
    }
    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    if (context.orders.productsList) {
      setFiltredList(context.orders.productsList);
    }
  }, [context.orders.productsList]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  useEffect(() => {

    if (context.orders.message === "order saved") {
      successMessage( "La commande a été créé avec succès!");
      history.push("/orders")
    }
    console.log(context.orders?.message)
    if (context.orders.message === "order successfully updated!") {
      successMessage( "La commande a été modifiée avec succès!");
    }
    // eslint-disable-next-line
  }, [context.orders.message])


  function successMessage(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_ORDERS',
      payload : null
    });
  }

  function handleChangeProducts(id , qty ) {
    var productsList = [...form.products.filter(item => item !== id)];
    for (let i = 0; i < qty; i++) {
      productsList.push(id);
    }
    setForm({...form, products: productsList });
  }

  function submit() {
    var data = {
      ...form,
    }
    if (!order?._id) {
      data.user = context?.auth?.user._id;
      delete data.provider;
      postOrderAction(dispatch , data );
    } else {
      delete data.provider;
      putOrderAction(dispatch, data, order?._id);
    }

  }

  function renderProductsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList
          .filter(item => item.company._id === form.provider)
          .filter((item) => {
            if (!search) {
              return item;
            }
            if (search) {
              if (item.description.toLowerCase().includes(search.toLowerCase())) {
                return item;
              }
              if (item.ref.toLowerCase().includes(search.toLowerCase())) {
                return item;
              }
              if (item.gencod.toLowerCase().includes(search.toLowerCase())) {
                return item;
              }
            }
            return null
          })
          .map(( product , i) => <ProductsRow key={i} handleChangeProducts={handleChangeProducts} product={product} products={form.products}/>);
      } else if (filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  if (order?._id && !provider?.label) {
    return null;
  }

  return (
    <div className="page-container">
      <header className="page-header">

        <div className="icon">
          <img src={providerIcon} alt="icon-provider"/>
        </div>

        <h1>
          {!order?._id ? "Création": "Modification"} d'une commande {provider?.label &&  `pour ${provider?.label}`}
        </h1>

        </header>

        <main className="page-content">

          <Link to={"/orders"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <div className="row space-between">
            <div className="col-3">
              <Select
                value={shopsOptions?.find((d) => d.value === form?.company)}
                options={shopsOptions}
                onChange={(val) => setForm({...form, company: val.value })}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un magasin"
                />
            </div>
            <div className="col-3">
              {domainsOptions?.length > 0 && <Select
                value={domainsOptions?.find((d) => d.value === form?.domain)}
                options={domainsOptions}
                onChange={(val) => setForm({ ...form, domain: val.value })}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un lieu de vente"
                />
              }
            </div>
            <div className="col-3">
              <Select
                value={providersOptions?.find((d) => d.value === form?.provider)}
                options={providersOptions}
                onChange={(val) => setForm({...form, provider: val.value })}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un fournisseur"
                />
            </div>

            {form.products.length > 0 &&
              <button
                onClick={() => submit()}
                className="btn-save"
                >Valider
              </button>
            }

          </div>
          {form?.provider &&
            <div className="row" style={{ borderTop: "1px solid #B2B2B2", paddingTop: 10, marginTop: 5, marginRight: 20 }}>
              <div className="col-3">
                <Input
                  value={search}
                  type={"text"}
                  className="input"
                  placeholder="Recherche"
                  onChange={(value) => setSearch(value)}
                />
              </div>
              <div className="col-3">
              </div>
              <div className="col-3">
              </div>
            </div>
          }

          {provider?.name &&
            <div className="filters">

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Nom du fournisseur</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Segment</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByRef(!sortByRef)}>
                <span>Référence</span>
                <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByGencode(!sortByGencode)}>
                <span>Gencod</span>
                <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"

                onClick={() => setSortByPack(!sortByPack)}>
                <span>Colistage</span>
                <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                style={{ minWidth: '250px'}}
                onClick={() => setSortByTotal(!sortByTotal)}>
                <span>Prix net salon HT/unité</span>
                <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
              </div>

              <div className="filter">
                <span>Nbre de colis</span>
              </div>


            </div>
          }

        {form?.provider && renderProductsList()}

        </main>
    </div>
  );
  
};